import { always, identity } from '@cloudsmith/utils/functions';

/**
 * The result type is modelled after the return value of useSWR.
 * This way we can make sure our data fetching always has the
 * same signature, no matter if it happened client side or
 * server side.
 *
 * @typedef {Object} Result
 * @property {Object|null} data
 * @property {Object|null} error
 */

/**
 * @param {Object} data
 * @returns {Result}
 */
export const fromData = (data) => ({ data, error: null });

/**
 * @param {Object} error
 * @returns {Result}
 */
export const fromError = (error) => ({ data: null, error });

/**
 * @private
 * @param {Result}
 * @param {{ data: Function, error: Function }} fns
 * @returns {any}
 */
const matchResult = (result, { data = identity, error = identity } = {}) => {
  if (!result.error) {
    return data(result);
  } else {
    return error(result);
  }
};

/**
 * @param {Result} result
 * @param {Function} cb
 * @returns {any}
 */
export const withData = (result, cb) =>
  matchResult(result, { data: ({ data }) => cb(data) });

/**
 * @param {Result} result
 * @param {Function} cb
 * @returns {any}
 */
export const withError = (result, cb) =>
  matchResult(result, { error: ({ error }) => cb(error) });

/**
 * @param {Result} result
 * @param {Function} fn
 * @returns {Result}
 */
export const mapData = (result, fn) =>
  matchResult(result, { data: ({ data }) => fromData(fn(data)) });

/**
 * @param {Array<Result>} results
 * @param { { flatten: boolean } } options
 * @returns {Result}
 */
export const mergeData = (results, { flatten = false } = {}) => {
  const data = results.map((r) => r.data);
  return fromData(flatten ? data.flat() : data);
};

/**
 * @param {Array<Result>} results
 * @param { { flatten: boolean } } options
 * @params {Result}
 */
export const mergeErrors = (results, { flatten = false } = {}) => {
  const errors = results.map((r) => r.error);
  return fromError(flatten ? errors.flat() : errors);
};

/**
 * Takes an array of results and merges them into a single result.
 * If a single result contains an error, the merged result will
 * also be an error.
 *
 * @param {Array<Result>} results
 * @param { { strict: boolean, flattenData: boolean } } options
 * @returns {Result}
 */
export const flattenResults = (
  results,
  { strict = false, flattenData = false } = {},
) => {
  if (results.error) return results;

  const errors = results.filter((r) => !!r.error);
  const data = results.filter((r) => !r.error);

  if (errors.length && strict) {
    return errors[0];
  }

  const mergedData = data.map((r) => r.data);

  if (flattenData) {
    return fromData(mergedData.flat());
  } else {
    return fromData(mergedData);
  }
};

/**
 * Unwraps the data from the result or throws the error
 * contained in the result.
 *
 * @param {Result} result
 * @returns {Object}
 * @throws
 */
export const dataOrThrow = (result) =>
  matchResult(result, {
    error: ({ error }) => {
      throw error;
    },
    data: ({ data }) => data,
  });

/**
 * Unwraps data from a result or returns a provided
 * fallback value if the result is an error.
 *
 * @param {Result} result
 * @returns {Object}
 */
export const dataOrFallback = (result, fallback) =>
  matchResult(result, {
    error: always(fallback),
    data: ({ data }) => data,
  });

/**
 * Wraps potentially throwing code in a try-catch block
 * and returns a result object.
 *
 * @param {Function} fn
 * @returns {Result|Promise<Result>}
 */
export const fromTryCatch = async (fn) => {
  try {
    return fromData(await fn());
  } catch (error) {
    return fromError(error);
  }
};
