import { apiUrl } from '@cloudsmith/utils/api';
import {
  filterPaginationData,
  isUrlPaginated,
} from '@cloudsmith/utils/pagination';
import { fromData, fromError } from '@cloudsmith/utils/result';

/**
 * @param {string} url
 * @param {object} authorization
 * @param {Object} options
 * @param {(string) => string} urlFactory - optional function to create the URL. Defaults to `apiUrl` which produces a Cloudsmith API URL
 * @returns {Result}
 */
export const apiFetcher = async (
  url,
  authorization = {},
  options,
  urlFactory = apiUrl,
) => {
  const endpoint = urlFactory ? urlFactory(url) : url;

  let headers = {
    ...authorization,
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...(options?.headers ?? {}),
  };

  if (typeof window === 'undefined') {
    // allow setting clientHeaders to null to override check
    if (options.clientHeaders === undefined) {
      const error = new Error(
        'Error: Missing client headers for server side request',
        endpoint,
      );
      return fromError({
        message: error.message,
        endpoint,
      });
    }
    // add client headers if server side request
    headers = {
      ...headers,
      ...options.clientHeaders, // ip address & user agent
      'CS-Webapp-Secret': process.env.CS_WEBAPP_SECRET,
    };
  }

  let res;

  try {
    res = await fetch(`${endpoint}`, {
      ...options,
      next: {
        // SEE: https://nextjs.org/docs/app/building-your-application/data-fetching/fetching-caching-and-revalidating#on-demand-revalidation
        // We cap the cache to the max of 256 characters, as longer strings such as access tokens will result in a Next error
        // We should be far far over any chances of collission between access tokens
        tags: options?.cacheTag
          ? [options.cacheTag.join('').substring(0, 256)]
          : [],
      },
      headers,
    });
  } catch (e) {
    return fromError(e);
  }

  if (!res.ok) {
    let info;

    // Trying to JSON parse the response for a non ok status code assumes
    // the request still got a valid API response. If it doesn’t we’ll pass
    // along the fetch response
    try {
      info = await res.json();
    } catch (e) {
      info = res;
    }

    const error = new Error(
      `(${res.status}) An error occurred while fetching the data.`,
    );

    return fromError({
      message: error.message,
      ...info,
      status: res.status,
      endpoint,
    });
  }

  if (options?.ignoreOutput) {
    return fromData(null);
  } else {
    const json = await res.json();
    if (isUrlPaginated(endpoint)) {
      const paginationData = filterPaginationData(res);
      if (Object.keys(paginationData).length > 0) {
        return fromData({ items: json, pagination: paginationData });
      }
    }
    return fromData(json);
  }
};
