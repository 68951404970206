export const periodTypes = {
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
  CUSTOM: 'custom',
  CURRENT_BILLING: 'current_billing',
  PREV_BILLING: 'prev_billing',
  PREV_7_DAYS: 'prev_7_days',
  PREV_30_DAYS: 'prev_30_days',
  PREV_12_MONTHS: 'prev_12_months',
};
