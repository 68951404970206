import { periodTypes } from '@cloudsmith/utils/constants/time';

export const identity = (x) => x;

export const always = (x) => () => x;

/**
 * A util to make conditional variable assignment
 * a bit more readable than nested ternaries.
 *
 * @param {Array<[boolean, T]>} options
 * @returns {T | null}
 */
export const match = (...options) => {
  for (const [predicate, value] of options) {
    if (predicate) {
      return value;
    }
  }

  return null;
};

/**
 * A lazy version of match, where the value is
 * a function that returns the value.
 *
 * @param {Array<[boolean, () => T]>} options
 * @returns {T | null}
 */
export const lazyMatch = (...options) => {
  for (const [predicate, value] of options) {
    if (predicate) {
      if (typeof value === 'function') {
        return value();
      }
    }
  }

  return null;
};

/**
 * A simple util to copy selected keys of an object into a new object.
 * keys can be an array of string or an object where the keys will be used.
 *
 * @param {object>} obg
 * @returns {array|object} keys
 */
export const pick = (obj, toCopy) => {
  const keys = Array.isArray(toCopy) ? toCopy : Object.keys(toCopy);
  const result = {};
  for (let i = 0; i < keys.length; i++) {
    result[keys[i]] = obj[keys[i]];
  }
  return result;
};

/**
 * Compares if items in one array match items in another array.
 *
 * @param {Array} a
 * @param {Array} b
 * @returns {boolean}
 */
export const arrayEquals = (a, b) => {
  return a.reduce((acc, item, index) => {
    return acc && item === b[index];
  }, true);
};

/**
 * Compares if two objects are equal (deep equal)
 *
 * @param {Object} a
 * @param {Object} b
 * @returns {boolean}
 */
export const objectEquals = (a, b) => {
  const ta = typeof a;
  const tb = typeof b;

  return a && b && ta === 'object' && ta === tb
    ? Object.keys(a).length === Object.keys(b).length &&
        Object.keys(a).every((key) => objectEquals(a[key], b[key]))
    : a === b;
};

/**
 * Puts a function on the event loop to execute it on the
 * next tick. This is useful for when you need to run code
 * like a state update after a component has performed its
 * DOM effects to avoid conflicting DOM updates.
 *
 * An example is triggering a Dialog from a DropdownItem.
 * Clicking the DropdownItem causes the Dropdown to close,
 * removing its focus trap from the body. Opening the Dialog
 * also mutates the DOM, by setting its own focus trap on the
 * body. Performing opening and closing at the same time can
 * create a "race condition", where the closing Dropdown would
 * pick up the Dialogs focus trap and remove it immediately,
 * putting the DOM in a non-accessible state.
 *
 * @see https://github.com/radix-ui/primitives/issues/1241
 */
export const performOnNextTick = (fn) => {
  if (typeof window === 'undefined') {
    return;
  }
  requestAnimationFrame(fn);
};

/**
 * Looks up an object's key for a value.
 * Useful for reverse lookups on status enums.
 *
 * @param {any} value
 * @param {object} obj
 * @returns {string | null}
 */
export const lookupKey = (value, obj) => {
  for (const key in obj) {
    if (obj[key] === value) {
      return key;
    }
  }
  return null;
};

/**
 * Map a time unit to a number of ms
 */
export const unitToMs = {
  minute: 1000 * 60,
  hour: 1000 * 60 * 60,
  day: 1000 * 60 * 60 * 24,
  week: 1000 * 60 * 60 * 24 * 7,
};

/**
 * Checks if a value is not empty, null or undefined.
 *
 * @param {any} value
 * @returns {boolean}
 */

export const hasValue = (value) => {
  return value !== null && value !== undefined && value !== '';
};

/**
 *
 */
export const getLaterTime = (startTime, num, period) => {
  if (!startTime) {
    return null;
  }

  const endDate = new Date(startTime);

  if (period === periodTypes.MONTH) {
    endDate.setMonth(endDate.getMonth() + num);
  } else if (period === periodTypes.WEEK) {
    endDate.setDate(endDate.getDate() + num * 7);
  } else if (period === periodTypes.YEAR) {
    endDate.setFullYear(endDate.getFullYear() + num);
  } else {
    throw 'Period not implemented';
  }

  return endDate.toISOString();
};

/**
 * Turns a nested object into a flat object with
 * dot separated keys.
 *
 * @param {object} obj
 * @param {string} separator
 * @returns {object}
 */
export const flattenObject = (obj, separator = '.') => {
  function walk(into, obj, prefix = []) {
    Object.entries(obj).forEach(([key, val]) => {
      if (typeof val === 'object' && !Array.isArray(val))
        walk(into, val, [...prefix, key]);
      else into[[...prefix, key].join(separator)] = val;
    });
  }
  const out = {};
  walk(out, obj);
  return out;
};

/**
 * Turns a flat object with dot separated keys into a nested object.
 *
 * @param {object} obj
 * @param {string} separator
 * @returns {object}
 */
export const unflattenObject = (obj, separator = '.') => {
  const out = {};
  Object.entries(obj).forEach(([key, val]) => {
    const keys = key.split(separator);
    keys.reduce((acc, key, index) => {
      if (index === keys.length - 1) acc[key] = val;
      else if (!acc[key]) acc[key] = {};
      return acc[key];
    }, out);
  });
  return out;
};

/**
 * Ensures that a value is an array.
 *
 * @param {T|null} value
 * @return{Array<T>|null}
 */
export const ensureArray = (value) => {
  if (value === null || value === undefined) return [];
  return Array.isArray(value) ? value : [value];
};

/**
 * Returns array without duplicate values
 *
 * @param {Array<T>} array
 * @return{Array<T>}
 */
export const uniqueArray = (array) => {
  return [...new Set(array)];
};
