import { dataOrFallback } from '@cloudsmith/utils/result';

/**
 * Set of HTTP headers that hold pagination data, as defined by Cloudsmith's API.
 * @see https://help.cloudsmith.io/reference/pagination
 */
const paginationKeys = {
  'x-pagination-count': 'itemCount',
  'x-pagination-page': 'page',
  'x-pagination-pagetotal': 'pageTotal',
  'x-pagination-pagesize': 'pageSize',
};

/**
 * @param {Response} res
 * @returns {boolean}
 */
export const isUrlPaginated = (url) => {
  try {
    const urlObj = new URL(url);

    return (
      urlObj.searchParams.has('page') && urlObj.searchParams.has('page_size')
    );
  } catch (e) {
    return false;
  }
};

/**
 * Returns pagination data object from a response object.
 * @param {Response} response
 * @returns {Object} `{page, itemCount, pageTotal, pageSize}`
 */
export const filterPaginationData = (response) => {
  return Object.fromEntries(
    Object.keys(paginationKeys)
      .map((key) => [key, response.headers.get(key)])
      .filter(([_, value]) => value !== undefined)
      .map(([key, value]) => [paginationKeys[key], parseInt(value)]),
  );
};

/**
 * Checks if a result is paginated.
 *
 * @param {Result} result
 * @returns {boolean}
 */
export const isResultPaginated = (result) => {
  return result.items !== undefined && result.pagination !== undefined;
};

/**
 * Maps data to the paginated form
 */
export const ensurePaginated = (data) => {
  if (data === null || data === undefined) return data;
  if (isResultPaginated(data)) return data;

  return {
    items: data,
    pagination: {
      itemCount: data.length,
      page: 1,
      pageTotal: 1,
      pageSize: data.length,
    },
  };
};

/**
 * Checks if a result is entirely empty
 *
 * @param {Result} result
 * @param {Object} options
 * @returns {boolean}
 */
export const isResultEmpty = (result, { query, defaultQuery } = {}) => {
  const data = ensurePaginated(dataOrFallback(result, []));

  return (
    data?.pagination?.itemCount === 0 && (!query || query === defaultQuery)
  );
};

/**
 * Adds pagination to a URL
 *
 * @param {string} url
 * @param {Object} pagination
 * @returns {string}
 */
export const urlWithPagination = (
  url,
  { page = null, pageSize = null, ...rest } = {},
) => {
  const paginationPresent = page != null && pageSize != null;

  const searchParams = new URLSearchParams({
    ...(paginationPresent && { page }),
    ...(paginationPresent && { page_size: pageSize }),
    ...rest,
  });

  if (paginationPresent) return `${url}?${searchParams.toString()}`;
  return url;
};
