import {
  NOT_FOUND_STATUS_CODE,
  NOT_IN_PLAN_STATUS_CODE,
  PRIVILEGE_ESCALATION_STATUS_CODE,
} from '@cloudsmith/utils/constants/api';
import { always, lazyMatch } from '@cloudsmith/utils/functions';

export const internalUserApiKey = process.env.INTERNAL_USER_API_KEY;

export const serviceUserApiKey = (workspace) => {
  const key = `${workspace.replace('-', '_').toUpperCase()}_SERVICE_USER_API_KEY`;
  return process.env[key];
};

export const apiUrl = (path) =>
  `${process.env.NEXT_PUBLIC_API_ENDPOINT}${path}`;

/**
 * Utility function to create an X-API-KEY header
 * @param {string} apiKey
 */
export const apiKeyHeader = (apiKey) => ({
  'X-API-KEY': apiKey,
});

/**
 * Utility to create a JWT Auth header
 * @param {string} token
 */
export const jwtAuthHeader = (token) => ({
  Authorization: `Bearer ${token}`,
});

/**
 * Utility to create a CSRF token header
 * @param {string} token
 */
export const csrfTokenHeader = (token) => ({
  'X-CSRF-Token': token,
});

/**
 * Tries to get a meaningful error message from the
 * API response. Returns null if no message could be
 * found, so the caller can use a sensible default message.
 *
 * @param {Object} response
 * @returns {string|null}
 */
export const getApiErrorMessage = (error) => {
  try {
    const hasFieldsKey = Object.keys(error.fields ?? {}).length > 0;
    const hasDetails = error.detail && error.detail !== '';
    const hasMessage = error.message && error.message !== '';

    const pickFirstFromFields = (fields) => {
      const value = Object.values(fields ?? { first: null })[0];

      if (value === null || value === undefined) return null;
      if (typeof value === 'string') return value;
      if (Array.isArray(value) && value.length > 0) {
        const first = value[0];
        if (typeof first === 'string') return first;
        if (typeof first === 'object') return pickFirstFromFields(first);
        return null;
      }
      if (typeof value === 'object') return pickFirstFromFields(value);

      return null;
    };

    const res = lazyMatch(
      [hasFieldsKey, () => pickFirstFromFields(error.fields)],
      [hasDetails, always(error.detail)],
      [hasMessage, always(error.message)],
      [true, always(null)],
    );

    return res !== '' ? res : null;
  } catch {
    return null;
  }
};

/**
 * Parses an API response, to check if privilege
 * escalation is needed.
 *
 * @param {Result} res
 * @returns {boolean}
 */
export const needsPrivilegeEscalation = (res) => {
  return res.error && res.error.status === PRIVILEGE_ESCALATION_STATUS_CODE;
};

/**
 * Checks if the API response indicates a 404
 *
 * @param {Result} res
 * @returns {boolean}
 */
export const wasNotFound = (res) => {
  return res.error && res.error.status === NOT_FOUND_STATUS_CODE;
};

/**
 * Parses an API response to check if the
 * the response indicates the need of a plan
 * upgrade.
 *
 * @param {Result} res
 * @returns {boolean}
 */
export const needsPlanUpgrade = (res) => {
  return res.error && res.error.status === NOT_IN_PLAN_STATUS_CODE;
};
